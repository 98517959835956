import { GaLowLevelEffect, type GaVueComponent } from "@/common/vueUtils";
import type { Store } from "@/common/storeUtils";
import type { DeepReadonly } from "vue";

export type GaSuspenseState<T> =
  | {
      kind: "fallback";
    }
  | {
      kind: "loaded";
      data: T;
    }
  | {
      kind: "error";
    };

export function getInitialSuspendState<T>(): GaSuspenseState<T> {
  return { kind: "fallback" };
}

export function GaSuspense<T>(props: { store: Store<GaSuspenseState<T>>; fetch: () => Promise<T>; fallback: () => GaVueComponent; loaded: (data: DeepReadonly<T>) => GaVueComponent; error: () => GaVueComponent }): GaVueComponent {
  const unionStore = props.store.unpackUnion();

  return (
    <GaLowLevelEffect
      sideEffect={async () => {
        if (props.store.get().kind === "fallback") {
          try {
            const result = await props.fetch();
            props.store.set({ kind: "loaded", data: result });
          } catch (_) {
            props.store.set({ kind: "error" });
          }
        }
      }}
      content={unionStore.kind === "fallback" ? props.fallback() : unionStore.kind === "loaded" ? props.loaded(unionStore.store.sub("data").get()) : props.error()}
    />
  );
}
