import { createParentComponent, type GaVueComponent } from "@/common/vueUtils";
import type { ContentIxStatus, ContentMetadata, Language } from "@newgenerated/shared/schema";
import { GaBadge } from "@/components/general/GaBadge";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { GaSuspense, type GaSuspenseState, getInitialSuspendState } from "@/common/GaSuspense";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import type { Store } from "@/common/storeUtils";
import "./_content-card.scss";

type Layout = "vertical" | "horizontal";

function getLink(path: string | null, additionalParams?: URLSearchParams): string | null {
  if (path === null) {
    return null;
  }

  try {
    const link = new URL(path, location.href);
    if (additionalParams !== undefined) {
      for (const [key, value] of additionalParams) {
        link.searchParams.append(key, value);
      }
    }
    return link.href;
  } catch (_) {
    return null;
  }
}

function Cover(props: { content: ContentMetadata }): GaVueComponent {
  switch (props.content.contentType) {
    case "SUMMARY": {
      return (
        <div class="card-cover-background--summary" style={props.content.backgroundColor !== null ? "--card-cover-bg-color: " + props.content.backgroundColor + ";" : ""}>
          <object class="card-cover-img" data={props.content.common.coverUri + "?s=S"} type="image/jpeg">
            {
              // Fallback image: gray (400) surface
            }
            <img class="card-cover-img-fallback" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR4AWNYuenIfwAHRgMfaGsqcAAAAABJRU5ErkJggg==" alt="" />
          </object>
        </div>
      );
    }
    case "MIAB": {
      return (
        <div class="card-cover-background--miab">
          <img src="/www/images/miab/layer-1.svg" class="card-cover-background--miab-layer" alt="" />
          <object class="card-cover-img" data={props.content.common.coverUri + "?s=S"} type="image/jpeg">
            {
              // Fallback image: gray (400) surface
            }
            <img class="card-cover-img-fallback" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR4AWNYuenIfwAHRgMfaGsqcAAAAABJRU5ErkJggg==" alt="" />
          </object>
          <img src="/www/images/miab/layer-2.svg" class="card-cover-background--miab-layer2" alt="" />
        </div>
      );
    }
    case "ACTIONABLE":
    case "SKETCHNOTE": {
      return (
        <object class="card-cover-img" data={props.content.common.coverUri}>
          {
            // < !--Fallback image: gray(200) surface-- >
          }
          <img class="card-cover-img-fallback" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR4AWO4+/j1fwAI9wOrhrsKQwAAAABJRU5ErkJggg==" alt="" />
        </object>
      );
    }
  }
}

function Badges(props: { translationFn: Store<GaSuspenseState<TranslationFn>>; content: ContentMetadata }): GaVueComponent {
  const { loadTranslation, t } = useGaContext();

  function Loaded(p: { t: TranslationFn }): GaVueComponent | null {
    function SourceBadge(): GaVueComponent {
      if (props.content.common.inactive) {
        return (
          <GaBadge caption={true} variant="muted">
            {p.t("browse:icon.inprogress")}
          </GaBadge>
        );
      }
      switch (props.content.contentType) {
        case "SUMMARY": {
          return (
            <GaBadge caption={true} variant="primary-light">
              {p.t("summary:source." + props.content.sourceType)}
            </GaBadge>
          );
        }
        case "ACTIONABLE": {
          return (
            <GaBadge caption={true} variant="purple-light">
              {p.t("actionable:source.actionable")}
            </GaBadge>
          );
        }
        case "SKETCHNOTE": {
          return (
            <GaBadge variant="tertiary-light" caption={true}>
              {p.t("lgxp:source.sketchnotes")}
            </GaBadge>
          );
        }
        case "MIAB": {
          return (
            <GaBadge variant="orange-light" caption={true}>
              {p.t("miab:source.miab")}
            </GaBadge>
          );
        }
      }
    }

    function AudioBadge(): GaVueComponent | null {
      if (props.content.contentType === "SUMMARY" && props.content.common.audioActive) {
        return <GaBadge variant="primary-light" icon="ico-earphones" />;
      }
      return null;
    }

    function MiabBadge(): GaVueComponent | null {
      if (props.content.contentType === "SUMMARY" && props.content.meetingInABoxAvailable) {
        return <GaBadge variant="primary-light" icon="ico-people" />;
      }
      return null;
    }

    return (
      <>
        <SourceBadge />
        <AudioBadge />
        <MiabBadge />
      </>
    );
  }

  return (
    <div class="card-badges">
      <GaSuspense
        store={props.translationFn}
        fallback={() => (
          <div class="placeholder-wave">
            <div class="placeholder" style="height: 1.3125rem; width: 5rem"></div>
          </div>
        )}
        fetch={() => loadTranslation(props.content.common.language)}
        loaded={(t: TranslationFn) => <Loaded t={t} />}
        /* If translation can't be loaded, we fallback (error case) to current language. */
        error={() => <Loaded t={t} />}
      />
    </div>
  );
}

function Body(props: { translationFn: Store<GaSuspenseState<TranslationFn>>; content: ContentMetadata; link: string | null; target?: string }): GaVueComponent {
  return (
    <div class="card-body">
      <Badges translationFn={props.translationFn} content={props.content} />

      {props.content.common.leadIn !== null && props.content.common.leadIn.trim() !== "" ? (
        props.link !== null ? (
          <a href={props.link} target={props.target} tabindex="-1">
            <p class="card-author small" title={props.content.common.leadIn}>
              {props.content.common.leadIn}
            </p>
          </a>
        ) : (
          <p class="card-author small" title={props.content.common.leadIn}>
            {props.content.common.leadIn}
          </p>
        )
      ) : null}

      {props.content.common.title.trim() !== "" ? (
        props.link !== null ? (
          <a class="focus-ring d-block" href={props.link} target={props.target}>
            <h4 class="card-title" title={props.content.common.title}>
              {props.content.common.title}
            </h4>
          </a>
        ) : (
          <h4 class="card-title" title={props.content.common.title}>
            {props.content.common.title}
          </h4>
        )
      ) : null}

      {props.content.common.description !== null && props.content.common.description.trim() !== "" ? (
        props.link !== null ? (
          <a href={props.link} target={props.target} tabindex="-1">
            <p class="card-text small" title={props.content.common.description}>
              {props.content.common.description}
            </p>
          </a>
        ) : (
          <p class="card-text small" title={props.content.common.description}>
            {props.content.common.description}
          </p>
        )
      ) : null}
    </div>
  );
}

type Variant = "danger";

const ActionButton = createParentComponent<{ variant?: Variant }>(({ variant, children }) => {
  const computedClasses = ["btn", "action-button", variant === "danger" ? "action-button--danger" : ""];
  return <button class={computedClasses}>{children}</button>;
});

function Actions(props: { content: ContentMetadata; allowBookmark: boolean; allowDelete: boolean; ixStatus?: ContentIxStatus }): GaVueComponent | null {
  // <!-- How do we handle the situation with customer in vue? And can the content really be empty like in the JSP-Tag -->
  if (!(props.allowDelete || (props.content.contentType === "SUMMARY" && props.allowBookmark))) {
    return null;
  }
  return (
    <div class="card-actions">
      {props.allowDelete ? (
        <div class="card-actions-left">
          <ActionButton variant="danger">
            <i class="ico-trash-bin" />
          </ActionButton>
        </div>
      ) : null}
      {props.content.contentType === "SUMMARY" && props.allowBookmark ? (
        <div class="card-actions-right">
          <ActionButton>
            {
              // <!-- TODO: implementation missing -->
            }
            {props.ixStatus !== undefined && props.ixStatus.bookmarkedAt !== null ? <i class="ico-bookmark-fill"></i> : <i class="ico-bookmark"></i>}
          </ActionButton>
        </div>
      ) : null}
    </div>
  );
}

export type TranslationFnAsync = GaSuspenseState<TranslationFn>;
export type TranslationFnsAsync = Record<Language, GaSuspenseState<TranslationFn>>;

export function getInitialSuspendTranslationFns(): TranslationFnsAsync {
  return {
    de: getInitialSuspendState(),
    en: getInitialSuspendState(),
    es: getInitialSuspendState(),
    fr: getInitialSuspendState(),
    pt: getInitialSuspendState(),
    ru: getInitialSuspendState(),
    zh: getInitialSuspendState(),
  };
}

export function ContentCard(props: {
  translationFn: Store<TranslationFnAsync>;
  content: ContentMetadata;
  layout: Layout;
  link?: string;
  additionalParams?: URLSearchParams;
  target?: string;
  allowBookmark?: boolean;
  allowDelete?: boolean;
  ixStatus?: ContentIxStatus;
}): GaVueComponent {
  const allowBookmark = props.allowBookmark ?? true;
  const allowDelete = props.allowDelete ?? false;

  const cardLink = getLink(props.link ?? props.content.common.link, props.additionalParams);
  const cardClasses = ["content-card", "content-card--" + props.layout, props.content.common.inactive ? "content-card--inactive" : "", cardLink === null || cardLink.trim() === "" ? "content-card--no-link" : ""];

  return (
    <div class={cardClasses} lang={props.content.common.language}>
      <div class="card-cover">
        {cardLink !== null ? (
          <a href={cardLink} target={props.target} tabindex="-1">
            <Cover content={props.content} />
          </a>
        ) : (
          <Cover content={props.content} />
        )}
      </div>
      <Body translationFn={props.translationFn} content={props.content} link={cardLink} />
      <Actions content={props.content} ixStatus={props.ixStatus} allowDelete={allowDelete} allowBookmark={allowBookmark} />
    </div>
  );
}
